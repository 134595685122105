import {HistoryItem} from '../entity/history-item';
import {v4 as uuidv4} from 'uuid';

class HistoryItemFactory {
    /**
     * @param {Product} product
     * @return {HistoryItem}
     */
    createForProduct(product) {
        const item = new HistoryItem();
        item.setId(uuidv4());
        item.setProduct(product);
        item.setStatus(HistoryItem.STATUS_NEW);
        return item;
    }

    /**
     * @param {Product} product
     * @param {String} jobId
     * @param {Number} copies
     * @return {HistoryItem}
     */
    createForProductAndJobId(product, jobId, copies) {
        const item = this.createForProduct(product);
        item.setRelatedPrintJobId(jobId);
        item.setCopies(copies);
        item.setStatus(HistoryItem.STATUS_NEW);
        return item;
    }

    /**
     * @param {String} id
     * @param {Product} product
     * @param {String} relatedPrintJobId
     * @param {String} status
     * @param {Number} copies
     * @return {HistoryItem}
     */
    createWithOptions(id, product, relatedPrintJobId, status, copies) {
        const historyItem = new HistoryItem();
        historyItem.setId(id);
        historyItem.setProduct(product);
        historyItem.setStatus(status);
        historyItem.setRelatedPrintJobId(relatedPrintJobId);
        historyItem.setCopies(copies);
        return historyItem;
    }
}

export const historyItemFactory = new HistoryItemFactory();
