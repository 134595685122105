export class Message {
    /**
     * @type {String}
     */
    message;

    /**
     * @type {String}
     */
    type;

    constructor(message, type) {
        this.message = message;
        this.type = type;
    }

    getMessage() {
        return this.message;
    }

    getType() {
        return this.type;
    }
}
