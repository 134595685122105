import {Store} from './Store';
import {historyItemSerializer} from '../serializer/history-item-serializer';

class HistoryStore {
    /**
     * @type {Store}
     */
    store;

    constructor() {
        this.store = new Store('scan_history');
    }

    /**
     * @param {HistoryItem[]} items
     */
    setItems(items) {
        this.store.set(items.map(item => historyItemSerializer.serialize(item)));
    }

    /**
     * @return {HistoryItem[]}
     */
    getItems() {
        const data = this.store.get();
        if (data === null || !Array.isArray(data)) {
            return [];
        }
        return data.map(item => historyItemSerializer.deserialize(item));
    }
}

export const historyStore = new HistoryStore();
