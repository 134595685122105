import {html, LitElement} from 'lit-element';

import styles from './app-autocomplete-empty.scss';

class AppAutocompleteEmpty extends LitElement {
    static get styles() {
        return [styles];
    }

    render() {
        return html`
            <div class="autocomplete-empty">
                Geen resultaten
            </div>
        `;
    }
}

customElements.define('app-autocomplete-empty', AppAutocompleteEmpty);
