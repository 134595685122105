export class Store {
    /**
     * @type {String}
     */
    name;

    constructor(name) {
        this.name = name;
    }

    /**
     * @param {any} data
     */
    set(data) {
        localStorage.setItem(this.buildKey(), JSON.stringify(data));
    }

    /**
     * @return {null|any}
     */
    get() {
        const data = localStorage.getItem(this.buildKey());
        if (data === null) {
            return null;
        }
        return JSON.parse(data);
    }

    buildKey() {
        return `store_${this.name}`;
    }
}
