export class Debounce {
    /**
     * @type {null|Number}
     */
    timeout = null;

    /**
     * @type {Number}
     */
    intervalMs;

    /**
     * @type {function}
     */
    callback;

    constructor(callback, intervalMs) {
        this.callback = callback;
        this.intervalMs = intervalMs;
    }

    reset() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(this.callback, this.intervalMs);
    }

    resetImmediate() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(this.callback, 0);
    }

    cancel() {
        if (this.timeout === null) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = null;
    }
}
