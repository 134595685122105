import {PreferenceStore} from './preference-store';
import {Printer} from './entity/printer';

class PrinterManager {
    /**
     * @type {PreferenceStore}
     */
    prefsStore;

    /**
     * @type {Printer[]}
     */
    printerCache = [];

    /**
     * @type {Boolean}
     */
    isPrinterCacheFresh = false;

    constructor() {
        this.prefsStore = new PreferenceStore('printerManager');
    }

    /**
     * @return {Promise<null|Printer>}
     */
    async getSelectedPrinter() {
        const id = this.prefsStore.get('printerId');
        const printer = (await this.getPrinters()).find(printer => printer.getId() === id);
        return printer === undefined ? null : printer;
    }

    setSelectedPrinter(printer) {
        this.prefsStore.set('printerId', printer === null ? null : printer.getId());
    }

    async getPrinters() {
        if (this.isPrinterCacheFresh) {
            return this.printerCache;
        }

        const printers = await window.printing.getPrinters();
        const processedIds = [];
        for (const printer of printers) {
            const current = this.printerCache.find(p => p.getId() === printer.id);
            if (current === undefined) {
                this.printerCache.push(new Printer(printer.id, printer.name));
            }
            processedIds.push(printer.id);
        }

        this.printerCache = this.printerCache.filter(printer => processedIds.indexOf(printer.getId()) !== -1);

        this.isPrinterCacheFresh = true;
        setTimeout(() => {
            this.isPrinterCacheFresh = false;
        }, 5000);
        return this.printerCache;
    }
}

export const printerManager = new PrinterManager();
