import {productFactory} from './factory/product-factory';

export class Backend {
    /**
     * @type {String}
     */
    baseUrl;

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        while (this.baseUrl.endsWith('/')) {
            this.baseUrl = this.baseUrl.substring(this.baseUrl.length - 2);
        }
    }

    /**
     * @param {String} query
     * @param {?AbortController} abortController
     * @return {Promise<Product[]>}
     */
    async searchByQuery(query, abortController) {
        const params = new URLSearchParams();
        params.append('query', query);

        const url = this.buildUrl('/search/query') + '?' + params.toString();
        const options = {};

        if (abortController !== null) {
            options.signal = abortController.signal;
        }

        const response = await fetch(url, options);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const results = await response.json();
        return results.map(product => this.mapProductDto(product));
    }

    /**
     * @param {String} ean
     * @return {Promise<Product[]>}
     */
    async searchByEan(ean) {
        const params = new URLSearchParams();
        params.append('ean', ean);

        const response = await fetch(this.buildUrl('/search/ean') + '?' + params.toString());
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const results = await response.json();
        return results.map(product => this.mapProductDto(product));
    }

    /**
     * @param {String} sku
     * @param {Number} copies
     * @return {Promise}
     */
    async fetchPdf(sku, copies) {
        const response = await fetch(
            this.buildUrl('/render-pdf/' + sku + '?copies=' + (!Number.isInteger(copies) || copies < 1 ? 1 : copies))
        );
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.blob();
    }

    /**
     * @param {Object} product
     * @return {Product}
     */
    mapProductDto(product) {
        return productFactory.createWithOptions(product.sku, product.name, product.ean, product.image);
    }

    buildUrl(path) {
        let formattedPath = path;
        while (formattedPath.startsWith('/')) {
            formattedPath = formattedPath.substring(1);
        }
        return this.baseUrl + '/' + formattedPath;
    }
}
