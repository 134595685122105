import {productSerializer} from './product-serializer';
import {historyItemFactory} from '../factory/history-item-factory';

class HistoryItemSerializer {
    /**
     * @param {HistoryItem} historyItem
     * @return {Object}
     */
    serialize(historyItem) {
        return {
            id: historyItem.getId(),
            product: productSerializer.serialize(historyItem.getProduct()),
            relatedPrintJobId: historyItem.getRelatedPrintJobId(),
            status: historyItem.getStatus(),
            copies: historyItem.getCopies(),
        };
    }

    /**
     * @param {Object} data
     * @return {HistoryItem}
     */
    deserialize(data) {
        return historyItemFactory.createWithOptions(
            data.id,
            productSerializer.deserialize(data.product),
            data.relatedPrintJobId,
            data.status,
            data.copies,
        );
    }
}

export const historyItemSerializer = new HistoryItemSerializer();
