export class AutocompleteRequestHandler {
    /**
     * @type {Backend}
     */
    backend;

    /**
     * @type {AbortController}
     */
    abortController = null;

    constructor(backend) {
        this.backend = backend;
    }

    fetchSuggestions(query) {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();
        return this.backend.searchByQuery(query, this.abortController);
    }

    cancel() {
        if (this.abortController === null) {
            return;
        }
        this.abortController.abort();
        this.abortController = null;
    }
}
