import {html, LitElement} from 'lit-element';
import {ifDefined} from 'lit-html/directives/if-defined';

import styles from './app-search-field.scss';

class AppSearchField extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            query: {type: String, reflect: true},
            hasAutocompleteSuggestions: {type: Boolean, state: true},
            loading: {type: Boolean},
        };
    }

    constructor() {
        super();
        this.hasAutocompleteSuggestions = false;
        this.loading = false;
    }

    render() {
        return html`
            <app-container>
                <div
                        class="search ${this.shouldShowAutocomplete() ? '-show-autocomplete' : ''}" 
                        ?loading=${this.loading}
                >
                    <div class="search__form">
                        <form @submit=${(e) => this.onSubmit(e)}>
                            <input type="search"
                                   placeholder="Zoek of scan een product"
                                   name="search"
                                   .value="${ifDefined(this.query)}"
                                   @input=${e => this.onInput(e)}
                            >
                            <button class="search__button" type="submit"></button>
                        </form>
                    </div>

                    <div class="search__autocomplete" data-element-autocomplete>
                        <slot name="autocomplete" @slotchange="${() => this.updateSuggestions()}"/>
                    </div>
                </div>
            </app-container>
        `;
    }

    shouldShowAutocomplete() {
        return this.hasAutocompleteSuggestions;
    }

    onInput() {
        this.query = this.shadowRoot.querySelector('input').value;
        this.dispatchEvent(new CustomEvent('change'));
    }

    onSubmit(e) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent('search'));
    }

    focus() {
        this.getInput().focus();
    }

    updateSuggestions() {
        const slot = this.getAutocompleteSlot();
        if (slot === null) {
            this.hasAutocompleteSuggestions = false;
        }
        this.hasAutocompleteSuggestions = slot.assignedElements().length > 0;
    }

    getAutocompleteSlot() {
        return this.shadowRoot.querySelector('slot[name="autocomplete"]');
    }

    getInput() {
        return this.shadowRoot.querySelector('input');
    }
}

customElements.define('app-search-field', AppSearchField);
