import {html, LitElement} from 'lit-element';

import styles from './app-dialog.scss';

class AppDialog extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            title: {type: String},
            icon: {type: String},
        };
    }

    autocompleteSuggestions = [];

    printHistory = [];

    render() {
        return html`
            <div class="dialog">
                <div class="dialog__content">
                    <img src="${this.icon}" class="dialog__icon">
                    <h5 class="dialog__title">${this.title}</h5>
                
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

customElements.define('app-dialog', AppDialog);
