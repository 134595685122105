import {html, LitElement} from 'lit-element';

class AppAutocomplete extends LitElement {
    static get properties() {
        return {
            suggestions: {type: Array},
            selectedIndex: {type: Number},
        };
    }

    constructor() {
        super();
        this.options = [];
    }

    render() {
        return html`
            <div class="autocomplete" @click=${e => this.onSlotClick(e)}>
                ${this.renderSuggestions()}
            </div>

            <slot @slotchange=${() => this.updateSuggestions()}/>
        `;
    }

    renderSuggestions() {
        return null;
    }

    onSlotClick() {
        // console.log(e.target);
    }

    updateSuggestions() {
        const slot = this.getSuggestionsSlot();
        if (slot === null) {
            return;
        }

        this.options = slot.assignedElements();
    }

    getSuggestionsSlot() {
        return this.shadowRoot.querySelector('slot');
    }
}

customElements.define('app-autocomplete', AppAutocomplete);
