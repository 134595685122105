import {v4 as uuidv4} from 'uuid';

export class PrintJob {
    /**
     * @type {String}
     */
    id;

    /**
     * @type {String}
     */
    remoteId;

    /**
     * @type {String}
     */
    printerId;

    /**
     * @type {String}
     */
    name;

    /**
     * @type {Function}
     */
    pdfProvider;

    /**
     * @type {?Promise<void>}
     */
    pdfPromise = null;

    /**
     * @type {?Blob}
     */
    pdf = null;

    /**
     * @type {String}
     */
    status;

    /**
     * @type {?Date}
     */
    jobStartTime = null;

    constructor(printerId, name, pdfProvider) {
        this.id = uuidv4();
        this.printerId = printerId;
        this.name = name;
        this.pdfProvider = pdfProvider;
        this.status = PrintJob.STATUS_NEW;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getRemoteId() {
        return this.remoteId;
    }

    setRemoteId(remoteId) {
        this.remoteId = remoteId;
    }

    getPrinterId() {
        return this.printerId;
    }

    getName() {
        return this.name;
    }

    /**
     * @return {Promise<Blob>}
     */
    getPdf() {
        if (this.pdf instanceof Blob) {
            return Promise.resolve(this.pdf);
        }

        if (this.pdfPromise === null) {
            this.pdfPromise = this.pdfProvider();
        }

        this.pdfPromise.then(blob => {
            this.pdf = blob;
            this.pdfPromise = null;
        });

        return this.pdfPromise;
    }

    getStatus() {
        return this.status;
    }

    setStatus(status) {
        this.status = status;
    }

    getJobStartTime() {
        return this.jobStartTime;
    }

    setJobStartTime(jobStartTime) {
        this.jobStartTime = jobStartTime;
    }
}

PrintJob.STATUS_NEW = 'new';
PrintJob.STATUS_PROCESSING = 'processing';
PrintJob.STATUS_CANCELLED = 'cancelled';
PrintJob.STATUS_FAILED = 'failed';
PrintJob.STATUS_FINISHED = 'finished';
