import {html, LitElement} from 'lit-element';

import styles from './app-autocomplete-suggestion.scss';

class AppAutoCompleteSuggestion extends LitElement {
    static get styles() {
        return [styles];
    }

    render() {
        return html`
            <div class="autocomplete-suggestion">
                <slot/>
            </div>
        `;
    }
}

customElements.define('app-autocomplete-suggestion', AppAutoCompleteSuggestion);
