import {html, LitElement} from 'lit-element';

import styles from './app-history.scss';

class AppHistory extends LitElement {
    static get styles() {
        return [styles];
    }

    render() {
        return html`
            <app-container> 
                <div class="app-history">
                    <h1>Geschiedenis</h1>

                    <div class="app-history__log">
                        <slot/>
                    </div>
                </div>
            </app-container>
        `;
    }
}

customElements.define('app-history', AppHistory);
