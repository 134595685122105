import {PrintJob} from './entity/print-job';
import {blobToBase64} from '../util/base64';

export class PrintJobManager extends EventTarget {
    /**
     * @type {PrintJob[]}
     */
    queue = [];

    /**
     * @type {PrintJob[]}
     */
    pendingJobs = [];

    constructor() {
        super();
        window.printing.addEventListener('onJobStatusChanged', e => this.onJobStatusChanged(e));
        setInterval(() => this.checkStaleJobs(), 5 * 1000);
    }

    /**
     * @param {PrintJob} job
     */
    submitJob(job) {
        this.queue.push(job);
        this.startNextJobIfNeeded();
    }

    startNextJobIfNeeded() {
        if (!this.canStartNextJob()) {
            return;
        }
        this.startNextJob();
    }

    async startNextJob() {
        const job = this.queue.shift();
        if (job === undefined) {
            return;
        }

        job.setJobStartTime(new Date());
        this.pendingJobs.push(job);
        const result = await this.submitToApi(job);
        job.setRemoteId(result.jobId);

        switch (result.status) {
            case 'USER_REJECTED':
                this.finishJob(job, PrintJob.STATUS_CANCELLED);
                break;
            case 'FAILED':
                this.finishJob(job, PrintJob.STATUS_FAILED);
                break;
            case 'OK':
                break;
            default:
                throw new Error(`Received unknown status "${result.status}" from Printing API`);
        }
    }

    onJobStatusChanged(event) {
        const job = this.pendingJobs.find(j => j.getRemoteId() === event.detail.jobId);
        if (job === undefined) {
            throw new Error(`Received status update for unknown job "${event.detail.jobId}"`);
        }

        switch (event.detail.jobStatus) {
            case 'PENDING':
            case 'IN_PROGRESS':
                this.updateJobStatus(job, PrintJob.STATUS_PROCESSING);
                break;
            case 'FAILED':
                this.finishJob(job, PrintJob.STATUS_FAILED);
                break;
            case 'CANCELED':
                this.finishJob(job, PrintJob.STATUS_CANCELLED);
                break;
            case 'PRINTED':
                this.finishJob(job, PrintJob.STATUS_FINISHED);
                break;
            default:
                throw new Error(`Received unknown status update "${event.detail.jobStatus}" for job "${job.id}"`);
        }
    }

    /**
     * @param {PrintJob} job
     * @return {Promise<{status: String, jobId: ?String}>}
     */
    async submitToApi(job) {
        /* eslint-disable camelcase */
        const jobOptions = {
            color: {type: 'STANDARD_MONOCHROME'},
            duplex: {type: 'NO_DUPLEX'},
            copies: {copies: 1},
            dpi: {horizontal_dpi: 300, vertical_dpi: 300},
            collate: {collate: false},
            page_orientation: {type: 'LANDSCAPE'},
            media_size: {
                height_microns: 25400,
                width_microns: 38100,
            },
        };
        /* eslint-enable */

        try {
            const pdf = await job.getPdf();
            return window.printing.submitJob(
                job.getPrinterId(),
                job.getName(),
                await blobToBase64(pdf),
                jobOptions
            );
        } catch (e) {
            return Promise.resolve({status: 'FAILED', jobId: null});
        }
    }

    canStartNextJob() {
        // Temporarily disabled to debug an issue. We rely on the ChromeOS queue for now.
        // return this.pendingJobs.length <= 1;
        return true;
    }

    /**
     * @param {PrintJob} job
     * @param {String} status
     */
    finishJob(job, status) {
        this.pendingJobs = this.pendingJobs.filter(j => j !== job);
        this.updateJobStatus(job, status);
        this.startNextJobIfNeeded();
    }

    /**
     * @param {PrintJob} job
     * @param {String} status
     */
    updateJobStatus(job, status) {
        job.setStatus(status);
        this.dispatchEvent(new CustomEvent('statuschange', {
            detail: {
                job: job,
            },
        }));
    }

    checkStaleJobs() {
        const staleJobs = [];
        const currentTime = new Date();
        for (const job of this.pendingJobs) {
            const diff = (currentTime.getTime() - job.getJobStartTime().getTime()) / 1000;
            if (diff > 30) {
                staleJobs.push(job);

                if (window.Sentry !== undefined) {
                    // eslint-disable-next-line no-undef
                    Sentry.captureMessage(
                        `Stopping printjob ${job.id} (${job.remoteId}) because it has been stale for ${diff} seconds`
                    );
                }
            }
        }

        for (const staleJob of staleJobs) {
            this.finishJob(staleJob, PrintJob.STATUS_FAILED);
        }
    }
}
