import {PrintJob} from './print-job';

export class HistoryItem {
    /**
     * @type {String}
     */
    id;

    /**
     * @type {Product}
     */
    product;

    /**
     * @type {String}
     */
    relatedPrintJobId;

    /**
     * @type {String}
     */
    status;

    /**
     * @type {Number}
     */
    copies;

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getProduct() {
        return this.product;
    }

    setProduct(product) {
        this.product = product;
    }

    getRelatedPrintJobId() {
        return this.relatedPrintJobId;
    }

    setRelatedPrintJobId(id) {
        this.relatedPrintJobId = id;
    }

    getStatus() {
        return this.status;
    }

    setStatus(status) {
        this.status = status;
    }

    getCopies() {
        return this.copies;
    }

    setCopies(copies) {
        this.copies = copies;
    }

    /**
     * @param {PrintJob} job
     */
    setStatusFromJob(job) {
        switch (job.getStatus()) {
            case PrintJob.STATUS_NEW:
                this.setStatus(HistoryItem.STATUS_NEW);
                break;
            case PrintJob.STATUS_PROCESSING:
                this.setStatus(HistoryItem.STATUS_PROCESSING);
                break;
            case PrintJob.STATUS_CANCELLED:
                this.setStatus(HistoryItem.STATUS_CANCELLED);
                break;
            case PrintJob.STATUS_FAILED:
                this.setStatus(HistoryItem.STATUS_FAILED);
                break;
            case PrintJob.STATUS_FINISHED:
                this.setStatus(HistoryItem.STATUS_FINISHED);
                break;
            default:
                this.setStatus(HistoryItem.STATUS_UNKOWN);
        }
    }
}

HistoryItem.STATUS_UNKOWN = 'unknown';
HistoryItem.STATUS_NEW = 'new';
HistoryItem.STATUS_PROCESSING = 'processing';
HistoryItem.STATUS_FAILED = 'failed';
HistoryItem.STATUS_CANCELLED = 'cancelled';
HistoryItem.STATUS_FINISHED = 'finished';
