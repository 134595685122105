import {html, LitElement} from 'lit-element';

import styles from './app-product-suggestion.scss';

class AppProductSuggestion extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            product: {type: Object},
        };
    }

    render() {
        return html`
            <article class="product-suggestion">
                <img class="product-suggestion__image" src="${this.product.imageUrl}" alt="${this.product.name}">
                <h2 class="product-suggestion__name">${this.product.name} - ${this.product.sku}</h2>
            </article>
        `;
    }
}

customElements.define('app-product-suggestion', AppProductSuggestion);
