import {html, LitElement} from 'lit-element';

import styles from './app-container.scss';

class AppContainer extends LitElement {
    static get styles() {
        return [styles];
    }

    render() {
        return html`
            <div class="container">
                <slot>
            </div>
        `;
    }
}

customElements.define('app-container', AppContainer);
