import {HistoryItem} from '../entity/history-item';

export function formatHistoryItemStatus(status) {
    switch (status) {
        case HistoryItem.STATUS_NEW:
            return 'Nieuw (wachtrij)';
        case HistoryItem.STATUS_PROCESSING:
            return 'Verwerken';
        case HistoryItem.STATUS_FAILED:
            return 'Mislukt';
        case HistoryItem.STATUS_CANCELLED:
            return 'Geannuleerd';
        case HistoryItem.STATUS_FINISHED:
            return 'Klaar';
        case HistoryItem.STATUS_UNKOWN:
        default:
            return 'Onbekend';
    }
}
