export class PreferenceStore {
    prefix;

    constructor(prefix) {
        this.prefix = prefix;
    }

    set(prefName, value) {
        localStorage.setItem(this.buildPrefName(prefName), value);
    }

    get(prefName) {
        return localStorage.getItem(this.buildPrefName(prefName));
    }

    buildPrefName(prefName) {
        return this.prefix + '_' + prefName;
    }
}
