import {html, LitElement} from 'lit-element';

import styles from './app-history-item.scss';
import {formatHistoryItemStatus} from '../../service/formatter/formatting';

class AppHistoryItem extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            product: {type: Object},
            status: {type: String},
            copies: {type: Number, state: true},
        };
    }

    /**
     * @type {HistoryItem}
     */
    item;

    constructor() {
        super();
        this.copies = 1;
    }

    render() {
        return html`
            <article class="history-item">
                <img width="100" height="100" src=${this.product.getImageUrl()} alt=${this.product.getName()}>
                <div class="history-item__content">
                    <div>
                        <span class="history-item__content__title">${this.product.getName()}</span>
                        <span>${this.product.getSku()}</span>
                        ${this.product.getEan() ? html`<span>EAN: ${this.product.getEan()}</span>` : ''}
                        <span>Status: ${formatHistoryItemStatus(this.status)}</span>
                    </div>

                    <div class="history-item__print">
                        <div class="history-item__print__count">
                            <span 
                                    class="history-item__print__action minus"
                                    @click=${() => this.decreaseClick()} 
                            >-</span>
                            <input class="history-item__print__input" type="text" .value=${this.copies} disabled>
                            <span 
                                    class="history-item__print__action plus"
                                    @click=${() => this.increaseClick()} 
                            >+</span>
                        </div>
                        <button @click=${() => this.printClick()} class="button" type="submit">Print</button>
                    </div>
                </div>
            </article>
        `;
    }

    increaseClick() {
        this.copies++;
    }

    decreaseClick() {
        this.copies--;
        if (this.copies < 1) {
            this.copies = 1;
        }
    }

    printClick() {
        this.dispatchEvent(new CustomEvent('reprint', {
            detail: {
                product: this.product,
                copies: this.copies,
            },
        }));
    }
}

customElements.define('app-history-item', AppHistoryItem);
