import {Product} from '../entity/product';

class ProductFactory {
    /**
     * @return {Product}
     */
    create() {
        return new Product();
    }

    /**
     * @param {String} sku
     * @param {String} name
     * @param {String} ean
     * @param {String} imageUrl
     * @return {Product}
     */
    createWithOptions(sku, name, ean, imageUrl) {
        const product = this.create();
        product.setSku(sku);
        product.setName(name);
        product.setEan(ean);
        product.setImageUrl(imageUrl);
        return product;
    }
}

export const productFactory = new ProductFactory();
