import {LitElement} from 'lit-element';

class AppPrinterSelectOption extends LitElement {
    static get properties() {
        return {
            value: {type: String},
            name: {type: String},
            selected: {type: Boolean},
        };
    }

    constructor() {
        super();
        this.selected = false;
    }
}

customElements.define('app-printer-select-option', AppPrinterSelectOption);
