import {html, LitElement} from 'lit-element';

import logo from '../../../components/images/blabloom-logo.svg';
import mobileLogo from '../../../components/images/blabloom-logo-mobile.svg';
import printerIcon from '../../../components/images/printer.svg';

import styles from './app-header.scss';

class AppHeader extends LitElement {
    static get styles() {
        return [styles];
    }

    render() {
        return html`
            <app-container>
                <header class="page-header">
                    <img class="logo -tablet-desktop-hidden" src="${mobileLogo}" alt="Blabloom logo">
                    <img class="logo -mobile-hidden" src="${logo}" alt="Blabloom logo">
                    
                    <div class="page-header__printer">
                        <img class="page-header__printer__icon" src="${printerIcon}" alt="Select print icon">
                        <slot nam="printer"></slot>
                    </div>
                </header>
            </app-container>
        `;
    }
}

customElements.define('app-header', AppHeader);
