import {html, LitElement} from 'lit-element';

import styles from './app-printer-select.scss';

class AppPrinterSelect extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            options: {type: Array, reflect: true},
            selectedIndex: {type: Number, reflect: true},
        };
    }

    constructor() {
        super();
        this.options = [];
    }

    render() {
        return html`
            <select @change="${e => this.onSelectionChange(e)}">
                <option></option>
                ${this.renderOptions()}
            </select>

            <slot @slotchange="${() => this.updateOptions()}"></slot>
        `;
    }

    onSelectionChange(e) {
        e.stopPropagation();

        const element = e.target;
        this.selectedIndex = this.options.findIndex(
            option => option.value === element.options[element.selectedIndex].value
        );
        this.dispatchEvent(new CustomEvent('change'));
    }

    updateOptions() {
        const slot = this.getOptionSlot();
        if (slot === null) {
            return;
        }

        this.options = slot.assignedElements();
    }

    renderOptions() {
        return this.options.map(option => html`
            <option value="${option.value}" ?selected=${option.selected}>${option.innerHTML}</option>
        `);
    }

    getOptionSlot() {
        return this.shadowRoot.querySelector('slot');
    }
}

customElements.define('app-printer-select', AppPrinterSelect);
