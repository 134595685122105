import {html, LitElement} from 'lit-element';

import styles from './app-message.scss';
import closeImage from '../../../components/images/close.svg';

export class AppMessage extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            type: {type: String},
        };
    }

    constructor() {
        super();
        this.type = AppMessage.TYPE_NOTICE;
    }

    render() {
        return html` 
            <article class="app-message ${this.type === AppMessage.TYPE_ERROR ? '-error' : '-default'}">
                <div class="app-message__content">
                    <slot></slot>
                </div>
 
                <button class="app-message__close" @click=${e => this.onCloseClick(e)}>
                    <img src=${closeImage} alt="close message">
                </button>
            </article>
        `;
    }

    onCloseClick(e) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent('close'));
    }
}

AppMessage.TYPE_NOTICE = 'notice';
AppMessage.TYPE_ERROR = 'error';

customElements.define('app-message', AppMessage);
