export class ExtensionLoader {
    state = ExtensionLoader.STATE_PENDING;

    timeoutId;

    waitForExtension() {
        if (typeof window.printing !== 'undefined') {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            window.addEventListener('printingApiInitialized', () => {
                this.state = ExtensionLoader.STATE_LOADED;
                resolve();

                if (this.timeoutId !== null) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            }, {once: true});

            this.timeoutId = setTimeout(() => {
                this.state = ExtensionLoader.STATE_FAILED;
                reject(new Error('Could not load extension'));
            }, 1000);
        });
    }

    getState() {
        return this.state;
    }
}

ExtensionLoader.STATE_PENDING = 'pending';
ExtensionLoader.STATE_LOADED = 'loaded';
ExtensionLoader.STATE_FAILED = 'failed';

export const extensionLoader = new ExtensionLoader();
