import {productFactory} from '../factory/product-factory';

class ProductSerializer {
    /**
     * @param {Product} product
     * @return {Object}
     */
    serialize(product) {
        return {
            sku: product.getSku(),
            name: product.getName(),
            ean: product.getEan(),
            imageUrl: product.getImageUrl(),
        };
    }

    /**
     * @param {Object} data
     * @return {Product}
     */
    deserialize(data) {
        return productFactory.createWithOptions(
            data.sku,
            data.name,
            data.ean,
            data.imageUrl,
        );
    }
}

export const productSerializer = new ProductSerializer();
