export class Product {
    /**
     * @type {?String}
     */
    sku = null;

    /**
     * @type {?String}
     */
    name = null;

    /**
     * @type {?String}
     */
    ean = null;

    /**
     * @type {?String}
     */
    imageUrl = null;

    getSku() {
        return this.sku;
    }

    setSku(sku) {
        this.sku = sku;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getEan() {
        return this.ean;
    }

    setEan(ean) {
        this.ean = ean;
    }

    getImageUrl() {
        return this.imageUrl;
    }

    setImageUrl(url) {
        this.imageUrl = url;
    }
}
